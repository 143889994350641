<template>
  <div class="p-4 d-flex flex-column align-content-center">
    <div style="font-size: 34px">Download reports</div>
    <div style="font-size: 18px">
      Make and download reports regarding a time period. See each report
      description for details
    </div>
    <div class="shadow-sm w-50 mx-auto px-4 my-5">
      <reports-search></reports-search>
    </div>
  </div>
</template>
<script>
import ReportsSearch from "../components/reports/ReportsSearch";
export default {
  name: "MReports",
  components: {
    ReportsSearch,
  },
};
</script>
