<template>
  <div>
    <div class="d-flex align-items-center justify-content-between my-3 p-3">
      <div style="font-size: larger">Select date range</div>
      <b-input-group
        class="justify-content-end"
        v-b-tooltip.hover="'Both days included'"
      >
        <!-- Date selector -->
        <m-date-select v-model="value" />
      </b-input-group>
    </div>

    <div
      v-for="report in reports"
      :key="report.code"
      class="d-flex align-items-center justify-content-between my-3 p-3"
    >
      <div style="font-size: larger">{{ report.title }}</div>
      <div class="px-2">{{ report.description }}</div>
      <b-button
        variant="primary"
        v-b-tooltip.hover="'Download report'"
        @click="exportReport(report.code)"
        ><i class="fas fa-download"></i
      ></b-button>
    </div>
  </div>
</template>

<script>
import MDateSelect from "../gadgets/MDateSelect.vue";
export default {
  name: "ReportsSearch",
  components: {
    MDateSelect,
  },
  data() {
    return {
      reports: [
        {
          code: "usedcodes",
          title: "Used coded",
          description: "List of users who entered a loyalty sticker code.",
        },
        {
          code: "orderedfreeproducts",
          title: "Free product orders",
          description:
            "Data of all users who ordered a free product for loyalty points.",
        },
        {
          code: "averageorderbycustomer",
          title: "Average order value",
          description: "Average order value by client",
        },
        {
          code: "usersbyproductsbrands",
          title: "Users by products brands",
          description:
            "Data of all users by ordered product brands (paid and free)",
        },
        {
          code: "lifetimeaveragevalues",
          title: "Lifetime average values per loyalty user",
          description: "Data of all users average values in selected period",
        },
        {
          code: "bcorders",
          title: "BC orders",
          description: "Detailed orders exported from BigCommerce",
        },
      ],
    };
  },
  methods: {
    async exportReport(code) {
      this.downloading = true;

      try {
        const from = this.value.start.toISOString().split("T")[0];
        const to = this.value.end.toISOString().split("T")[0];

        const res = await this.$axios.get(`/reports/${code}`, {
          params: {
            dateFrom: from,
            dateTo: to,
          },
          responseType: "blob",
        });

        this.downloadFile(res.data, `report-${code}-${from}-${to}`);
      } catch (e) {
        console.error(e);
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>
